import React, { Component } from "react";
import armenian_flag from "../assets/images/Armenian_Flag.png";

export default class History extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { hyes } = this.props;
    if (!hyes) {
      return (
        <React.Fragment>
          <section className="history" id="History">
            <img
              className="float-left"
              src={armenian_flag}
              alt="Armenian_Flag"
            />
            <div className="container">
              <h3>History</h3>
              <hr className="mission-line" />
              <p className="history-subtitle">
                “In the final judgment, a man’s accomplishments are measured by
                his contributions to the well-being of his fellow men. When a
                man can earn the respect and gratitude of his fellow men, and
                his community, he has achieved the true purpose of life and that
                is his true success.”
              </p>
              <p className="history-content">
                These words were written by Mrs. Siranoush Sanossian, whose
                philanthropic efforts led to the establishment of the Armenian
                Welfare Association of New York, a.k.a. The New York Armenian
                Home. The origins of the New York Armenian Home date back to the
                1920’s, when Mrs. Sanossian became convinced of the need for
                such an institution by the plight of many elderly Armenians who
                had been cast off by their families. She gradually enlisted the
                support of a group of devout Armenian ladies in the New York
                area. Despite many trials and tribulations, including ridicule,
                the Home became a reality, assuring that the Armenian elderly
                need no longer live their last years in loneliness, if their
                families were unable to care for them.
              </p>
              <p className="history-content">
                On November 17, 1948, the Armenian Welfare Association of New
                York was incorporated. No sooner were the doors opened, than
                there was a waiting list for hopeful entrants. Within six years
                the need for a larger facility became evident. In the summer of
                1954, a new location in Flushing, New York was purchased.
                Despite the initial upgrade, by 1957 it became clear that there
                was a need for more beds. A Building Fund Drive was launched,
                and after much consideration, the decision was made to add a new
                wing to the existing site. Three floors were added, allowing
                room for 80 residents.
              </p>
              <p className="history-content">
                For more than 65 years the New York Armenian Home has been
                caring for the frail and elderly. But as the Armenian community
                in the New York City tri-state area developed and thrived, the
                needs of our families have changed as well. There is now a need
                for a more modern facility with up-to-date amenities. In keeping
                with the expectations of our community, the Board of Directors
                and the membership decided to relocate and upgrade the New York
                Armenian Home. The Board entered into negotiations with the
                Armenian Nursing and Rehab Center in Emerson, NJ to jointly
                build and manage a new facility on the Emerson Home’s existing
                property.
              </p>
              <p className="history-content">
                The new facility will combine top-notch health care, memory
                care, and assisted living with exciting amenities and
                activities, into one vibrant and affordable lifestyle. In
                addition to enjoying an independent and active lifestyle, our
                residents’ families will have greater peace of mind knowing that
                continuing care is available – should anybody ever need it. We
                are planning to open our doors to all seniors in need of elder
                care. The Home’s motto - “Our doors and hearts are always open”
                - will remain the same as we hope to continue to serve our
                seniors for years to come.
              </p>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section className="history" id="History">
            <img
              className="float-left"
              src={armenian_flag}
              alt="Armenian_Flag"
            />
            <div className="container">
              <h3>Պատմություն</h3>
              <hr className="mission-line" />
              <p className="history-subtitle">
                «Վերջնական դատավճռում տղամարդու նվաճումները չափվում են իր
                ծառայակիցների բարեկեցությանը նպաստելու միջոցով: Երբ տղամարդը
                կարող է վաստակել իր ընկերների և իր համայնքի հարգանքն ու
                երախտագիտությունը, նա հասել է կյանքի իրական նպատակին, և դա է նրա
                իրական հաջողությունը »:
              </p>
              <p className="history-content">
                Այս բառերը գրեց տիկին Սիրանուշ Սանոսեանը, որի բարեգործական
                ջանքերը պատճառ դարձան Նյու Յորքի Հայ բարեկեցության
                ընկերակցության հիմնադրմանը, այսինքն `Նյու Յորքի հայկական տուն:
                Նյու Յորքի հայկական տան ծագումը սկիզբ է առել 1920-ականներից, երբ
                տիկին Սանոսյանը համոզվեց, որ այդպիսի ինստիտուտ է պետք `իրենց
                ընտանիքների կողմից վտարված շատ տարեց հայերի ծանր վիճակի մեջ: Նա
                աստիճանաբար օգնություն խնդրեց մի խումբ բարեպաշտ հայ տիկնայք Նյու
                Յորքի տարածքում: Չնայած բազմաթիվ փորձություններին և
                նեղություններին, ներառյալ ծաղրին, Տունը իրականություն դարձավ ՝
                հավաստիացնելով, որ հայ տարեցներն այլևս կարիք չունեն իրենց վերջին
                տարիներն ապրել միայնության մեջ, եթե նրանց ընտանիքները
                չկարողանային հոգ տանել նրանց մասին:
              </p>
              <p className="history-content">
                1948 թվականի նոյեմբերի 17-ին ստեղծվեց Նյու Յորքի Հայ
                բարեկեցության ասոցիացիան: Դռները շուտ բացվեցին, քան հուսադրող
                դիմորդների սպասման ցուցակն էր: Վեց տարվա ընթացքում ակնհայտ
                դարձավ ավելի մեծ հաստատության կարիքը: 1954-ի ամռանը ձեռք բերվեց
                Նյու Յորքի Ֆլուշինգ քաղաքում գտնվող նոր վայր: Չնայած նախնական
                նորացմանը, 1957 թ.-ին պարզ դարձավ, որ ավելի շատ մահճակալների
                կարիք կա: Մեկնարկեց Building Fund Drive- ը, և շատ ուշադրություն
                դարձնելուց հետո որոշում կայացվեց գոյություն ունեցող վայրում նոր
                թև ավելացնել: Երեք հարկ ավելացվեց, ինչը հնարավորություն է տալիս
                տեղավորել 80 բնակչի:
              </p>
              <p className="history-content">
                Ավելի քան 65 տարի Նյու Յորքի հայկական տունը հոգ էր տանում թույլ
                և տարեց մարդկանց մասին: Բայց քանի որ Նյու Յորքի եռապետական
                տարածքում հայկական համայնքը զարգանում և ծաղկում էր ապրում,
                փոխվել են նաև մեր ընտանիքների կարիքները: Այժմ անհրաժեշտություն
                կա ավելի ժամանակակից հաստատություն `արդի հարմարություններով:
                Հաշվի առնելով մեր համայնքի սպասելիքները ՝ Տնօրենների խորհուրդը և
                անդամությունը որոշեցին տեղափոխել և արդիականացնել Նյու Յորքի
                հայկական տունը: Խորհուրդը բանակցություններ է վարել Էմերսոն
                նահանգի Նեյջոր նահանգի հայկական բուժքույրական և վերականգնողական
                կենտրոնի հետ `Emerson Home- ի գոյություն ունեցող գույքի համատեղ
                կառուցման և կառավարման համար
              </p>
              <p className="history-content">
                Նոր հաստատությունը համատեղելու է բարձրակարգ առողջապահությունը,
                հիշողության խնամքը և օժանդակ կենցաղը հետաքրքիր
                հարմարություններով և գործողություններով `մեկ կենսունակ և մատչելի
                ապրելակերպի մեջ: Բացի ինքնուրույն և ակտիվ ապրելակերպ վայելելուց,
                մեր բնակիչների ընտանիքները ավելի մեծ հոգեկան հանգստություն
                կունենան ՝ իմանալով, որ շարունակական խնամք կա, եթե որևէ մեկը
                երբևէ դրա կարիքն ունենա: Մենք նախատեսում ենք մեր դռները բացել
                բոլոր տարեցների առջև, ովքեր տարեցների խնամքի կարիք ունեն: Տան
                կարգախոսը. «Մեր դռներն ու սրտերը միշտ բաց են» - կմնա նույնը, ինչ
                մենք հույս ունենք, որ տարիներ շարունակ կշարունակենք ծառայել մեր
                տարեցներին:
              </p>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}
