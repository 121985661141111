import React, { Component } from "react";
import Envelope from "../assets/images/Envelope.png";

export default class Letter extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { hyes } = this.props;
    if (!hyes) {
      return (
        <React.Fragment>
          <section
            className="container open-letter"
            id="OpenLetter"
            name="OpenLetter"
          >
            <img src={Envelope} className="envelope" alt="envelope" />
            <div className="letter">
              <h3>Open Letter</h3>
              <hr className="letter-line" />
              <p className="open-letter-writing">
                For over 65 years the New York Armenian Home has been caring for
                the frail and elderly. It has served as a home away from home
                for fellow Armenians, providing loving care in a culturally rich
                surrounding.
              </p>
              <p className="open-letter-writing">
                The Home’s mission began with a handful of Armenian Genocide
                survivors three generations ago. Regardless of political or
                church affiliation, the primary goal of the Home was to create a
                congenial surrounding, where the residents find a measure of
                happiness, while maintaining their Armenian culture.
              </p>
              <p className="open-letter-writing">
                As the Armenian community in the New York City tri-state area
                developed and thrived, the needs of our families have changed as
                well, and there is now a need for a more up to date facility
                with modern amenities. In keeping with the expectations of our
                community, our Board of Directors made a decision for the
                relocation and upgrade of the New York Armenian Home.
              </p>
              <p className="open-letter-writing">
                We plan to build a new facility where we will combine top-notch
                health care, exciting amenities and activities, into one vibrant
                and affordable lifestyle. In addition to an independent and
                active life, our residents’ families will enjoy greater peace of
                mind knowing that continuing care is available – should anybody
                ever need it. We are planning to open our doors to all seniors
                in need of elder care. The Home’s motto - “Our doors and hearts
                are always open” - will remain the same as we hope to continue
                to serve our seniors for years to come.
              </p>
              <h5 className="ending-open-letter">
                - Armenian Home of New York
              </h5>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section
            className="container open-letter"
            id="OpenLetter"
            name="OpenLetter"
          >
            <img src={Envelope} className="envelope" alt="envelope" />
            <div className="letter-armenian">
              <h3>Բաց նամակ</h3>
              <hr className="letter-line" />
              <p className="open-letter-writing">
                Ավելի քան 65 տարի Նյու Յորքի հայկական տունը հոգ էր տանում թույլ
                և տարեց մարդկանց մասին: Այն ծառայել է որպես հայրենիքից հեռու
                գտնվող տուն հայ հայրենակիցների համար ՝ սիրառատ խնամք ապահովելով
                մշակութային հարուստ շրջապատում:
              </p>
              <p className="open-letter-writing">
                «Տան» առաքելությունը սկսվել է երեք ցեղասպանություն վերապրած մի
                բուռ Հայոց ցեղասպանությունից: Անկախ քաղաքական կամ եկեղեցական
                պատկանելությունից, տան հիմնական նպատակը հարևան միջավայր ստեղծելն
                էր, որտեղ բնակիչները գտնում են երջանկության չափանիշ `պահպանելով
                իրենց հայկական մշակույթը:
              </p>
              <p className="open-letter-writing">
                Նյու Յորքի եռապետական տարածքում հայկական համայնքի զարգացման և
                բարգավաճման հետ մեկտեղ փոխվեցին նաև մեր ընտանիքների կարիքները, և
                այժմ կա ավելի հարմարավետ և ժամանակակից հարմարությունների կարիք:
                Հաշվի առնելով մեր համայնքի սպասելիքները ՝ մեր Տնօրենների
                խորհուրդը որոշում կայացրեց Նյու Յորքի հայկական տան վերաբնակեցման
                և արդիականացման վերաբերյալ:
              </p>
              <p className="open-letter-writing">
                Մենք նախատեսում ենք կառուցել նոր հաստատություն, որտեղ
                համատեղելու ենք գերժամանակակից առողջապահությունը, հետաքրքիր
                հարմարություններն ու գործողությունները մեկ կենսունակ և մատչելի
                ապրելակերպի մեջ: Բացի ինքնուրույն և ակտիվ կյանքից, մեր
                բնակիչների ընտանիքները ավելի մեծ հանգստություն կունենան ՝
                իմանալով, որ շարունակական խնամք կա, եթե որևէ մեկը երբևէ դրա
                կարիքն ունենա: Մենք նախատեսում ենք մեր դռները բացել բոլոր
                տարեցների առջև, ովքեր տարեցների խնամքի կարիք ունեն: Տան
                կարգախոսը. «Մեր դռներն ու սրտերը միշտ բաց են» - կմնա նույնը, ինչ
                մենք հույս ունենք, որ տարիներ շարունակ կշարունակենք ծառայել մեր
                տարեցներին:
              </p>
              <h5 className="ending-open-letter">- Նյու Յորքի հայկական տուն</h5>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}
