import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer id="sticky-footer" className="py-4 bg-dark text-white-50">
          <div className="text-center">
            <small>PO Box 754098, Forest Hills, New York, 11375</small>
          </div>
          <div className="container text-center">
            <small>Copyright &copy; armenianhomeny.org</small>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
