import React, { Component } from "react";

export default class CarouselSlide extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { hyes } = this.props;
    if (!hyes) {
      return (
        <React.Fragment>
          <section className="container photosvideos" id="Photos" name="Photos">
            <h3>Photos &amp; Videos</h3>
            <hr />
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <iframe
                    width="70%"
                    height="500"
                    src="https://www.youtube.com/embed/t2AxDUYGKcc"
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#carouselExampleControls"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselExampleControls"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section className="container photosvideos" id="Photos" name="Photos">
            <h3>Նկարներ &amp; Տեսանյութեր</h3>
            <hr />
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <iframe
                    width="70%"
                    height="500"
                    src="https://www.youtube.com/embed/t2AxDUYGKcc"
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#carouselExampleControls"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselExampleControls"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}
