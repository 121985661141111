import React, { Component } from "react";
import { Link } from "react-scroll";

export default class Header extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { handleToggle, hyes } = this.props;
    if (!hyes) {
      return (
        <React.Fragment>
          <header>
            <nav className="navbar navbar-expand-lg navbar-dark">
              <div className="d-flex flex-column title-div">
                <div className="p-2 title-div text-center">
                  <Link
                    activeClass="active"
                    className="navbar-brand nav-title"
                    to="top"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    Armenian Home of New York
                  </Link>
                  <button
                    className="navbar-toggler  mr-auto"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                </div>

                <div
                  className="collapse navbar-collapse barbar text-center"
                  id="navbarSupportedContent"
                >
                  <ul
                    className="navbar-nav list-inline mx-auto justify-content-center "
                    id="horizontal-list"
                  >
                    <li className="nav-item">
                      <Link
                        activeClass="active"
                        className="nav-link alink"
                        to="OpenLetter"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        Open Letter
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        activeClass="active"
                        className="nav-link alink"
                        to="Mission"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        Mission
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        activeClass="active"
                        className="nav-link alink"
                        to="History"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        History
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        activeClass="active"
                        className="nav-link alink"
                        to="Photos"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        Photos &amp; Videos
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        activeClass="active"
                        className="nav-link alink"
                        to="ProjectUpdates"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        Updates
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        activeClass="active"
                        className="nav-link alink"
                        to="ContactUs"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li className="nav-link">
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitches"
                          onChange={handleToggle}
                        />
                        <label
                          className="custom-control-label"
                          for="customSwitches"
                        >
                          Դու հայ ես?
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </header>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <header>
            <nav className="navbar navbar-expand-lg navbar-dark">
              <div className="d-flex flex-column title-div">
                <div className="p-2 title-div text-center">
                  <Link
                    activeClass="active"
                    className="navbar-brand nav-title nav-title-armenian"
                    to="top"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    Նյու Յորքի հայկական տուն
                  </Link>
                  <button
                    className="navbar-toggler mr-auto"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                </div>

                <div
                  className="collapse navbar-collapse barbar text-center"
                  id="navbarSupportedContent"
                >
                  <ul
                    className="navbar-nav list-inline mx-auto justify-content-center "
                    id="horizontal-list"
                  >
                    <li className="nav-item">
                      <Link
                        activeClass="active"
                        className="nav-link alink"
                        to="OpenLetter"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        Բաց նամակ
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        activeClass="active"
                        className="nav-link alink"
                        to="Mission"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        Առաքելություն
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        activeClass="active"
                        className="nav-link alink"
                        to="History"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        Պատմություն
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        activeClass="active"
                        className="nav-link alink"
                        to="Photos"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        Նկարներ &amp; Տեսանյութեր
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        activeClass="active"
                        className="nav-link alink"
                        to="ProjectUpdates"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        Projectրագրի թարմացումները
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        activeClass="active"
                        className="nav-link alink"
                        to="ContactUs"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        Կապվեք մեզ հետ
                      </Link>
                    </li>
                    <li className="nav-link">
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitches"
                          onChange={handleToggle}
                        />
                        <label
                          className="custom-control-label"
                          for="customSwitches"
                        >
                          English
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </header>
        </React.Fragment>
      );
    }
  }
}
