import React, { useState } from "react";
import "./App.css";

import Header from "./components/header";
import Mission from "./components/mission";
import Letter from "./components/letter";
import History from "./components/history";
import Contact from "./components/contact";
import Footer from "./components/footer";
import CarouselSlide from "./components/carousel";
import ProjectUpdates from "./components/project-updates";

function App() {
  const [hyes, setHyes] = useState(false);

  function updateHyesState() {
    if (hyes) {
      setHyes(false);
    } else {
      setHyes(true);
    }
  }

  return (
    <div className="App">
      <Header hyes={hyes} handleToggle={updateHyesState} />
      <Letter hyes={hyes} />
      <Mission hyes={hyes} />
      <History hyes={hyes} />
      <CarouselSlide hyes={hyes} />
      <ProjectUpdates hyes={hyes} />
      <Contact hyes={hyes} />
      <Footer />
    </div>
  );
}

export default App;
