import React, { Component } from "react";
import emailjs from "emailjs-com";

export default class Contact extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    name: "",
    subject: "",
    email: "",
    message: "",
  };

  handleNameChange = (event) => {
    this.setState({ name: event.target.value });
  };
  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };
  handleSubjectChange = (event) => {
    this.setState({ subject: event.target.value });
  };
  handleMessageChange = (event) => {
    this.setState({ message: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    console.log(event.target);

    emailjs
      .sendForm(
        "service_qllbwls",
        "template_vrql9ka",
        event.target,
        "user_VG28yTyf0b7fnBr2lyf71",
        "784d701f5e5a1477a2780e924dc68641"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    event.target.reset();
  };

  render() {
    const { hyes } = this.props;

    if (!hyes) {
      return (
        <React.Fragment>
          <section className="contact-us" id="ContactUs">
            <div className="container contact-form">
              <h2>Contact Us</h2>
              <hr />
              <p>* = required</p>
              <form
                onSubmit={this.handleSubmit}
                className="needs-validation"
                id="contactForm"
                name="contactForm"
              >
                <fieldset>
                  <div className="contact-response">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div>
                        <label htmlFor="name">
                          Name <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          size="35"
                          id="name"
                          name="name"
                          onChange={this.handleNameChange}
                          required
                        />
                      </div>

                      <div>
                        <label htmlFor="email">
                          Email <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          size="35"
                          id="email"
                          name="email"
                          onChange={this.handleEmailChange}
                          required
                        />
                      </div>

                      <div>
                        <label htmlFor="subject">
                          Subject<span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          size="35"
                          id="subject"
                          name="subject"
                          onChange={this.handleSubjectChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md 12 col-sm-12">
                      <label htmlFor="message">
                        Message <span className="required">*</span>
                      </label>
                      <textarea
                        as="text"
                        className="form-control"
                        cols="50"
                        rows="15"
                        name="message"
                        onChange={this.handleMessageChange}
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <input className="submit" type="Submit" value="Submit" />
                  </div>
                </fieldset>
              </form>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section className="contact-us" id="ContactUs">
            <div className="container contact-form">
              <h2>Կապվեք մեզ հետ</h2>
              <hr />
              <p>* = պահանջվում է </p>
              <form
                onSubmit={this.handleSubmit}
                id="contactForm"
                name="contactForm"
                className="needs-validation"
                noValidate
              >
                <fieldset>
                  <div className="contact-response">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div>
                        <label htmlFor="name">
                          Անուն: <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          size="35"
                          id="name"
                          name="name"
                          onChange={this.handleNameChange}
                          required
                        />
                      </div>

                      <div>
                        <label htmlFor="email">
                          Էլ: <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          size="35"
                          id="email"
                          name="email"
                          onChange={this.handleEmailChange}
                          required
                        />
                      </div>

                      <div>
                        <label htmlFor="subject">
                          Առարկա: <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          size="35"
                          id="subject"
                          name="subject"
                          onChange={this.handleSubjectChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md 12 col-sm-12">
                      <label htmlFor="message">
                        Հաղորդագրություն: <span className="required">*</span>
                      </label>
                      <textarea
                        as="text"
                        className="form-control"
                        cols="50"
                        rows="15"
                        name="message"
                        onChange={this.handleMessageChange}
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <input className="submit" type="Submit" value="Submit" />
                  </div>
                </fieldset>
              </form>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}
