import React, { Component } from "react";

export default class Mission extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { hyes } = this.props;
    if (!hyes) {
      return (
        <React.Fragment>
          <section className="mission-statement" id="Mission">
            <div className=" container mission-center">
              <div className="mission-blog">
                <h3>Mission Statement</h3>
                <hr className="mission-line" />
                <p>
                  The mission of the Armenian Home of New York is to provide a
                  caring, organized, well-maintained, full-service residential
                  facility for seniors, who, regardless of financial status, by
                  choice or otherwise, cannot or do not, wish to live alone.
                </p>
                <p>
                  The Home’s mission began with a handful of Armenian Genocide
                  survivors three generations ago. Regardless of political or
                  church affiliation, the primary goal of the Home is to create
                  congenial surroundings, where residents can find a measure of
                  happiness, while maintaining their Armenian culture.
                </p>
                <p>
                  We intend to continue to serve as a home away from home for
                  fellow Armenians, providing love and care in a culturally rich
                  surrounding.
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section className="mission-statement" id="Mission">
            <div className=" container mission-center">
              <div className="mission-blog">
                <h3>Առաքելությունը</h3>
                <hr className="mission-line" />
                <p>
                  Նյու Յորքի հայկական տան առաքելությունն է հոգատար,
                  կազմակերպված, բարեկարգ, լիարժեք բնակելի հաստատություն ապահովել
                  տարեցների համար, ովքեր, անկախ ֆինանսական կարգավիճակից, ըստ
                  իրենց ընտրության կամ այլ կերպ, չեն կարող կամ չեն ցանկանում
                  միայնակ ապրել: ,
                </p>
                <p>
                  «Տան» առաքելությունը սկսվել է երեք ցեղասպանություն վերապրած մի
                  բուռ Հայոց ցեղասպանությունից: Անկախ քաղաքական կամ եկեղեցական
                  պատկանելությունից ՝ Տան առաջնային նպատակն է ստեղծել հարազատ
                  միջավայր, որտեղ բնակիչները կարող են գտնել երջանկության չափանիշ
                  ՝ պահպանելով իրենց հայկական մշակույթը:
                </p>
                <p>
                  Մենք մտադիր ենք շարունակել ծառայել որպես հայրենիքից հեռու
                  գտնվող տուն հայրենակիցների համար ՝ սեր և խնամք ապահովելով
                  մշակութային հարուստ շրջապատում:
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}
