import React, { Component } from "react";
import Pdf1 from "../assets/documents/NYAH & Foundation of the NYAH - Website update.pdf";
import Pdf2 from "../assets/documents/NYAH and ANRC - Joint Corr 4-14-21 for Website.pdf";

export default class ProjectUpdates extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { hyes } = this.props;

    if (!hyes) {
      return (
        <React.Fragment>
          <section className="container project-updates" id="ProjectUpdates">
            <h3>Updates</h3>
            <hr />
            <div className="project-updates-content">
              <div className="row photovideorow">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">May 2021 Update</h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#May2021Modal"
                    >
                      See Update
                    </a>
                    <div
                      className="modal fade"
                      id="May2021Modal"
                      tabindex="-1"
                      aria-labelledby="May2021Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="May2021Label">
                              May 2021 Update
                            </h5>
                          </div>
                          <div className="modal-body">
                            <ul>
                              <li>
                                As noted in the Joint Letter of April 14, 2021,
                                ANRC recently closed its doors at 70 Main Street
                                in Emerson, New Jersey.
                              </li>
                              <li>
                                NYAH and ANRC continue their collective efforts
                                to develop a New Facility at 70 Main Street – we
                                can do it!
                              </li>
                              <li>
                                In other news, NYAH is separately assessing a
                                potential property in Great Neck. More to
                                follow!
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">April 29th, 2021 Update</h5>
                    <a href={Pdf1} target="_blank" className="btn btn-primary">
                      Open Update
                    </a>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">April 14th, 2021 Update</h5>
                    <a href={Pdf2} target="_blank" className="btn btn-primary">
                      Open Update
                    </a>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">March 2021 Update</h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#March2021Modal"
                    >
                      See Update
                    </a>
                    <div
                      className="modal fade"
                      id="March2021Modal"
                      tabindex="-1"
                      aria-labelledby="March2021Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="March2021Label">
                              March 2021 Update
                            </h5>
                          </div>
                          <div className="modal-body">
                            <p>
                              With all approvals received, the proceeds from the
                              July 30, 2018 sale of our property in Flushing are
                              finally released from escrow. We did it!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">January 2021 Update</h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#January2021Modal"
                    >
                      See Update
                    </a>
                    <div
                      className="modal fade"
                      id="January2021Modal"
                      tabindex="-1"
                      aria-labelledby="January2021Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="January2021Label">
                              January 2021 Update
                            </h5>
                          </div>
                          <div className="modal-body">
                            <p>
                              All our hard work pays off! On January 5, 2021,
                              the Supreme Court of Queens County signed an Order
                              authorizing the release of our sale proceeds from
                              escrow. In addressing the opposition filed by our
                              former member, the Court reviewed the opposition
                              papers and “finds the arguments presented therein
                              to be unavailing.” With those words, we’re now
                              able to move forward!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">November 2020 Update</h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#November2020Modal"
                    >
                      See Update
                    </a>
                    <div
                      className="modal fade"
                      id="November2020Modal"
                      tabindex="-1"
                      aria-labelledby="November2020Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="November2020Label">
                              November 2021 Update
                            </h5>
                          </div>
                          <div className="modal-body">
                            <p>
                              Unfortunately, one of our former members – with
                              three supporters – filed arguments opposing the
                              release of funds from escrow and the submission of
                              NYAH that had been endorsed by the NYS Charities
                              Bureau. Additionally, the same individuals
                              formally objected to the Affiliation Agreement of
                              March 28, 2019.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">September 2020 Update</h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#September2020Modal"
                    >
                      See Update
                    </a>
                    <div
                      className="modal fade"
                      id="September2020Modal"
                      tabindex="-1"
                      aria-labelledby="September2020Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="September2020Label">
                              September 2020 Update
                            </h5>
                          </div>
                          <div className="modal-body">
                            <ul>
                              <li>
                                More good news! NYAH received the endorsement of
                                the NYS Charities Bureau in connection with the
                                proposed release from escrow. With that
                                endorsement in hand, the final step in the
                                process is to obtain a signed Order from the
                                Supreme Court of Queens County.
                              </li>
                              <li>
                                NYAH files its Verified Application to Release
                                Funds from Escrow dated September 14, 2020, with
                                the Supreme Court of Queens County
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">July 2020 Update</h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#July2020Modal"
                    >
                      See Update
                    </a>
                    <div
                      className="modal fade"
                      id="July2020Modal"
                      tabindex="-1"
                      aria-labelledby="July2020Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="July2020Label">
                              July 2020 Update
                            </h5>
                          </div>
                          <div className="modal-body">
                            <ul>
                              <li>
                                Good news! On July 7, 2020, the NYS Department
                                of Health issued its formal “No Objection
                                Letter” in connection with the requested release
                                of funds from escrow by NYAH.
                              </li>
                              <li>
                                With the NYS Department of Health “No Objection
                                Letter” in hand, NYAH makes its final submission
                                to the NYS Charities Bureau for approval of
                                release of its sale proceeds from escrow.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">May 2020 Update</h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#May2020Modal"
                    >
                      See Update
                    </a>
                    <div
                      className="modal fade"
                      id="May2020Modal"
                      tabindex="-1"
                      aria-labelledby="May2020Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="May2020Label">
                              May 2020 Update
                            </h5>
                          </div>
                          <div className="modal-body">
                            <ul>
                              <li>
                                Another month of hard work down, and another
                                ahead! NYAH continued its efforts in
                                coordinating with the NYS Charities Bureau and
                                the NYS Department of Health. Additionally, NYAH
                                and ANRC continue their affiliation efforts,
                                although much focus and attention is consumed by
                                the COVID-19 Pandemic.
                              </li>
                              <li>Stay safe and healthy!</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">March 2020 Update</h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#March2020Modal"
                    >
                      See Update
                    </a>
                    <div
                      className="modal fade"
                      id="March2020Modal"
                      tabindex="-1"
                      aria-labelledby="March2020Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="March2020Label">
                              March 2020 Update
                            </h5>
                          </div>
                          <div className="modal-body">
                            <ul>
                              <li>
                                NYAH continues to assess additional sites in NYS
                                for potential future activities. Board
                                representatives identify and assess a potential
                                site in Hicksville.
                              </li>
                              <li>
                                Additionally, in February NYAH received feedback
                                from the NYS Charities Bureau regarding
                                additional information and documentation
                                requests. Also, final submission is made to NYS
                                Department of Health to obtain its consent and
                                formal “No Objection Letter.”
                              </li>
                              <li>
                                Finally, as the COVID-19 Pandemic began
                                effecting our communities, both NYAH and ANRC
                                sought to identify the best protections for our
                                seniors and the elderly in our communities.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">January 2020 Update</h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#January2020Modal"
                    >
                      See Update
                    </a>
                    <div
                      className="modal fade"
                      id="January2020Modal"
                      tabindex="-1"
                      aria-labelledby="January2020Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="January2020Label">
                              January 2021 Update
                            </h5>
                          </div>
                          <div className="modal-body">
                            <ul>
                              <li>
                                The license agreement relating to required
                                drainage and associated landscaping was
                                finalized and entered into by ANRC and the
                                Emerson Board of Education on December 16, 2019.
                                One more approval crossed off the list!
                              </li>
                              <li>
                                The Department of Health of the State of New
                                Jersey issued Functional Review Approval on
                                January 9, 2020, in connection with the proposed
                                construction of the New Facility.
                              </li>
                              <li>
                                Finally, with respect to the sale proceeds from
                                the sale of 137-31 45th Avenue, Flushing, New
                                York 11355, NYAH finalizes and submits its
                                initial application to the NYS Charities Bureau
                                for approval to release the sale proceeds from
                                escrow, allowing, among other things, the
                                approved funds to be utilized in furtherance of
                                the affiliation.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">November 2019 Update</h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#November2019Modal"
                    >
                      See Update
                    </a>
                    <div
                      className="modal fade"
                      id="November2019Modal"
                      tabindex="-1"
                      aria-labelledby="November2019Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="November2019Label">
                              November 2019 Update
                            </h5>
                          </div>
                          <div className="modal-body">
                            <p>
                              As the design and plans for the New Facility move
                              towards completion, NYAH and ANRC focus their
                              efforts on obtaining final approvals necessary to
                              bring the New Facility to fruition.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">September 2019 Update</h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#September2019Modal"
                    >
                      See Update
                    </a>
                    <div
                      className="modal fade"
                      id="September2019Modal"
                      tabindex="-1"
                      aria-labelledby="September2019Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="September2019Label">
                              September 2019 Update
                            </h5>
                          </div>
                          <div className="modal-body">
                            <p>
                              Following additional design proposals, NYAH and
                              ANRC hold another comprehensive status and
                              planning meeting on September 11, 2019. With input
                              from consultants and counsel, it seems the details
                              of the project are nearing final form!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">July 2019 Update</h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#July2019Modal"
                    >
                      See Update
                    </a>
                    <div
                      className="modal fade"
                      id="July2019Modal"
                      tabindex="-1"
                      aria-labelledby="July2019Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="July2019Label">
                              July 2019 Update
                            </h5>
                          </div>
                          <div className="modal-body">
                            <ul>
                              <li>
                                Consistent with the membership approvals of May
                                8, 2019, both NYAH and ANRC finalize amended
                                charters. Specifically, the Restated Certificate
                                of Incorporation of NYAH was filed with the NYS
                                Department of State, and, similarly, the
                                Restated Certificate of Incorporation of ANRC
                                was filed with the Department of Treasury of the
                                State of New Jersey.
                              </li>
                              <li>
                                In addition to governance and board approvals by
                                NYAH and ANRC, the organizations continue their
                                communications and collaborations relating to
                                the New Facility including a comprehensive
                                status and planning meeting held on July 22,
                                2019.
                              </li>
                              <li>
                                While there is much work to do and approvals to
                                obtain, we’re making progress!
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">May 2019 Update</h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#May2019Modal"
                    >
                      See Update
                    </a>
                    <div
                      className="modal fade"
                      id="May2019Modal"
                      tabindex="-1"
                      aria-labelledby="May2019Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="May2019Label">
                              May 2019 Update
                            </h5>
                          </div>
                          <div className="modal-body">
                            <ul>
                              <li>
                                At a Special Meeting of the Membership of NYAH
                                held on May 8, 2019, the Membership of NYAH
                                approved the Affiliation Agreement of March 28,
                                2019, entered into by NYAH, Armenian Home of New
                                York, Inc., ANRC, and The Armenian Home
                                Foundation, Inc.
                              </li>
                              <li>
                                Concurrent with our approvals on May 8, 2019,
                                the Membership of ANRC likewise approved the
                                Affiliation Agreement.
                              </li>
                              <li>We’re on our way!</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">July 2018 Update</h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#July2018Modal"
                    >
                      See Update
                    </a>
                    <div
                      className="modal fade"
                      id="July2018Modal"
                      tabindex="-1"
                      aria-labelledby="July2018Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="July2018Label">
                              July 2018 Update
                            </h5>
                          </div>
                          <div className="modal-body">
                            <p>
                              Following the sale of its real property on July
                              30, 2018, NYAH focused its efforts to recommence
                              operations by coordinating and collaborating with
                              its sister organization Home of the Armenian Aged,
                              Inc. d/b/a Armenian Nursing & Rehabilitation
                              Center (“ANRC”) in pursuit of the construction and
                              operation of a new long term care facility
                              servicing the elderly and needy.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">April 2018 Update</h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#April2018Modal"
                    >
                      See Update
                    </a>
                    <div
                      className="modal fade"
                      id="April2018Modal"
                      tabindex="-1"
                      aria-labelledby="April2018Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="April2018Label">
                              April 2018 Update
                            </h5>
                          </div>
                          <div className="modal-body">
                            <p>
                              Following the closure of our adult home on April
                              30, 2018, NYAH has continued its efforts to: (a)
                              identify a suitable location to construct a new
                              facility and recommence operations; (b) identify
                              an existing facility to acquire and recommence
                              operations; and (c) identify an organization with
                              which to affiliate for the purposes of
                              recommencing operations.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section className="container project-updates" id="ProjectUpdates">
            <h3>Projectրագրի թարմացումները</h3>
            <hr />
            <div className="project-updates-content">
              <div className="row photovideorow">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">2021 թ. Մայիսի թարմացում </h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#May2021Modal"
                    >
                      Տեսեք Թարմացում
                    </a>
                    <div
                      className="modal fade"
                      id="May2021Modal"
                      tabindex="-1"
                      aria-labelledby="May2021Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="May2021Label">
                              2021 թ. Մայիսի թարմացում
                            </h5>
                          </div>
                          <div className="modal-body">
                            <ul>
                              <li>
                                Ինչպես նշվում է 2021 թվականի ապրիլի 14-ի համատեղ
                                նամակում, ANRC- ն վերջերս փակեց իր դռները
                                Էմերսոն նահանգի Նեյ Jերսի նահանգի Մեյնթ փողոց 70
                                հասցեում:
                              </li>
                              <li>
                                NYAH- ը և ANRC- ը շարունակում են իրենց հավաքական
                                ջանքերը Մեյնթ փողոց 70-ում նոր կառույց ստեղծելու
                                ուղղությամբ. Մենք կարող ենք դա անել:
                              </li>
                              <li>
                                Այլ նորություններ, NYAH- ն առանձին է գնահատում
                                Մեծ պարանոցի հնարավոր սեփականությունը: Ավելին ՝
                                հետևելու համար:
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">
                      Ապրիլի 29-ը, 2021 թ. Թարմացում
                    </h5>
                    <a href={Pdf1} target="_blank" className="btn btn-primary">
                      Բացել թարմացումը
                    </a>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">
                      Ապրիլի 14-ը, 2021 թ. Թարմացում
                    </h5>
                    <a href={Pdf2} target="_blank" className="btn btn-primary">
                      Բացել թարմացումը
                    </a>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">2021 թ. Մարտի թարմացում </h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#March2021Modal"
                    >
                      Տեսեք Թարմացում
                    </a>
                    <div
                      className="modal fade"
                      id="March2021Modal"
                      tabindex="-1"
                      aria-labelledby="March2021Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="March2021Label">
                              2021 թ. Մարտի թարմացում
                            </h5>
                          </div>
                          <div className="modal-body">
                            <p>
                              Ստացված բոլոր հաստատումներով, 2018 թվականի հուլիսի
                              30-ին Ֆլուշինգում մեր գույքի վաճառքից ստացված
                              հասույթը վերջապես ազատվում է փախչելուց: Մենք
                              արեցինք դա!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Հունվար 2021 Թարմացում </h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#January2021Modal"
                    >
                      Տեսեք Թարմացում
                    </a>
                    <div
                      className="modal fade"
                      id="January2021Modal"
                      tabindex="-1"
                      aria-labelledby="January2021Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="January2021Label">
                              Հունվար 2021 Թարմացում
                            </h5>
                          </div>
                          <div className="modal-body">
                            <p>
                              Մեր բոլոր քրտնաջան աշխատանքներն իրենց արդյունքն են
                              տալիս: 2021 թվականի հունվարի 5-ին Քվինս շրջանի
                              Գերագույն դատարանը հրամանագիր ստորագրեց, որով
                              թույլատրվում է ազատել մեր վաճառքից ստացված
                              հասույթը էսկրոուից: Անդրադառնալով մեր նախկին
                              անդամի ներկայացրած ընդդիմությանը ՝ Դատարանը
                              վերանայեց ընդդիմության թերթերը և «դրանում
                              ներկայացված փաստարկներն անվավեր համարեց»: Այս
                              խոսքերով մենք այժմ կարող ենք առաջ շարժվել:
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">2020-ի նոյեմբերի թարմացում</h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#November2020Modal"
                    >
                      Տեսեք Թարմացում
                    </a>
                    <div
                      className="modal fade"
                      id="November2020Modal"
                      tabindex="-1"
                      aria-labelledby="November2020Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="November2020Label">
                              2020-ի նոյեմբերի թարմացում
                            </h5>
                          </div>
                          <div className="modal-body">
                            <p>
                              Դժբախտաբար, մեր նախկին անդամներից մեկը ՝ երեք
                              կողմնակիցներով, փաստարկներ ներկայացրեց, որոնք դեմ
                              էին NYS բարեգործական բյուրոյի կողմից հավանության
                              արժանացած էսկրոայից միջոցների ազատմանը և NYAH- ի
                              ներկայացմանը: Բացի այդ, նույն անձինք պաշտոնապես
                              առարկեցին 2019 թ. Մարտի 28-ին անդամակցության
                              պայմանագրի դեմ:
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Սեպտեմբերի 2020-ի թարմացում </h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#September2020Modal"
                    >
                      Տեսեք Թարմացում
                    </a>
                    <div
                      className="modal fade"
                      id="September2020Modal"
                      tabindex="-1"
                      aria-labelledby="September2020Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="September2020Label">
                              Սեպտեմբերի 2020-ի թարմացում
                            </h5>
                          </div>
                          <div className="modal-body">
                            <ul>
                              <li>
                                Ավելի լավ նորություն: NYAH- ը ստացավ NYS
                                բարեգործական բյուրոյի հավանությունը `կապված
                                էսկրոյից առաջարկվող ազատման հետ: Ձեռք բերելով
                                այդ հավանությունը, գործընթացի վերջին քայլը Քվինս
                                շրջանի Գերագույն դատարանից ստորագրված
                                կարգադրություն ստանալն է:
                              </li>
                              <li>
                                NYAH- ը Escrow- ից միջոցներ ազատելու մասին իր
                                հաստատված դիմումը ներկայացնում է Քվինս շրջանի
                                Գերագույն դատարան:
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">2020-ի հուլիսի թարմացում </h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#July2020Modal"
                    >
                      Տեսեք Թարմացում
                    </a>
                    <div
                      className="modal fade"
                      id="July2020Modal"
                      tabindex="-1"
                      aria-labelledby="July2020Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="July2020Label">
                              2020-ի հուլիսի թարմացում
                            </h5>
                          </div>
                          <div className="modal-body">
                            <ul>
                              <li>
                                Լավ լուր! 2020 թ. Հուլիսի 7-ին NYS- ի
                                Առողջապահության դեպարտամենտը թողարկեց իր
                                պաշտոնական «Առարկության նամակ» `կապված NYAH- ի
                                կողմից պահուստային միջոցներից պահանջվող ազատման
                                հետ:
                              </li>
                              <li>
                                NYS- ի Առողջության դեպարտամենտի «Առարկության ոչ
                                մի նամակ» ձեռքին, NYAH- ն իր վերջնական
                                ներկայացումն է ներկայացնում NYS բարեգործական
                                բյուրո `իր վաճառքից ստացված հասույթը ազատելու
                                հաստատման համար
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">2020 թ. Մայիսի թարմացում </h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#May2020Modal"
                    >
                      Տեսեք Թարմացում
                    </a>
                    <div
                      className="modal fade"
                      id="May2020Modal"
                      tabindex="-1"
                      aria-labelledby="May2020Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="May2020Label">
                              2020 թ. Մայիսի թարմացում
                            </h5>
                          </div>
                          <div className="modal-body">
                            <ul>
                              <li>
                                Եվս մեկ ամիս քրտնաջան աշխատանք, և ևս մեկ առաջ:
                                NYAH- ը շարունակեց ջանքերը `համակարգելու NYS
                                բարեգործական բյուրոյի և NYS առողջապահության
                                վարչության հետ: Բացի այդ, NYAH- ը և ANRC- ն
                                շարունակում են իրենց անդամակցության ջանքերը,
                                չնայած COVID-19 համաճարակի կողմից մեծ
                                ուշադրություն և ուշադրություն է ծախսվում:
                              </li>
                              <li>Մնացեք ապահով և առողջ:</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">2020 թ. Մարտի թարմացում </h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#March2020Modal"
                    >
                      Տեսեք Թարմացում
                    </a>
                    <div
                      className="modal fade"
                      id="March2020Modal"
                      tabindex="-1"
                      aria-labelledby="March2020Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="March2020Label">
                              2020 թ. Մարտի թարմացում
                            </h5>
                          </div>
                          <div className="modal-body">
                            <ul>
                              <li>
                                NYAH- ը շարունակում է գնահատել NYS- ի լրացուցիչ
                                կայքերը `հետագա հնարավոր գործունեության համար:
                                Խորհրդի ներկայացուցիչները բացահայտում և
                                գնահատում են Հիքսվիլ քաղաքում պոտենցիալ կայքը:
                                ՆՅԱ-ն շարունակում է գնահատել NYS- ի լրացուցիչ
                                կայքերը հետագա հնարավոր գործունեության համար:
                                Խորհրդի ներկայացուցիչները բացահայտում և
                                գնահատում են Հիքսվիլ քաղաքում գտնվող հավանական
                                կայքը:
                              </li>
                              <li>
                                Բացի այդ, փետրվարին NYAH- ը հետադարձ կապ ստացավ
                                NYS բարեգործական բյուրոյից `լրացուցիչ
                                տեղեկատվության և փաստաթղթավորման հարցումների
                                վերաբերյալ: Նաև, վերջնական ներկայացվում է NYS- ի
                                Առողջապահության դեպարտամենտ `դրա
                                համաձայնությունը և պաշտոնական« Առարկության նամակ
                                »ստանալու համար:
                              </li>
                              <li>
                                Վերջապես, երբ COVID-19 համաճարակն սկսեց ազդել
                                մեր համայնքների վրա, և NYAH- ը, և ANRC- ը
                                փորձեցին գտնել լավագույն պաշտպանությունը մեր
                                տարեցների և տարեցների համար մեր համայնքներում:
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Հունվար 2020 Թարմացում </h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#January2020Modal"
                    >
                      Տեսեք Թարմացում
                    </a>
                    <div
                      className="modal fade"
                      id="January2020Modal"
                      tabindex="-1"
                      aria-labelledby="January2020Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="January2020Label">
                              Հունվար 2020 Թարմացում
                            </h5>
                          </div>
                          <div className="modal-body">
                            <ul>
                              <li>
                                Պահանջվող ջրահեռացման և դրա հետ կապված
                                կանաչապատման հետ կապված լիցենզային պայմանագիրը
                                վերջնական տեսքի բերվեց և ANRC- ի և Էմերսոնի
                                կրթական խորհրդի կողմից ստորագրվեց 2019 թվականի
                                դեկտեմբերի 16-ին: Եվս մեկ հաստատում հատեց
                                ցուցակը:
                              </li>
                              <li>
                                Նյու erseyերսի նահանգի Առողջապահության
                                դեպարտամենտը թողարկել է Ֆունկցիոնալ վերանայման
                                հաստատում ՝ 2020 թվականի հունվարի 9-ին, կապված
                                Նոր Հաստատության կառուցման հետ:
                              </li>
                              <li>
                                Վերջապես, Նյու Յորքի 11355-ին Ֆլաշինգ, 137-31
                                45-րդ պողոտայի վաճառքից ստացված հասույթի մասով,
                                NYAH- ը վերջնական տեսքի է բերում և իր նախնական
                                հայտը ներկայացնում է NYS բարեգործական բյուրո
                                `վաճառքի հասույթը փորձն ազատելու թույլտվության
                                համար` թույլ տալով, ի թիվս այլոց իրերը,
                                հաստատված միջոցները, որոնք պետք է օգտագործվեն
                                անդամակցության հետագա զարգացման համար:
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">2019-ի նոյեմբերի թարմացում </h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#November2019Modal"
                    >
                      Տեսեք Թարմացում
                    </a>
                    <div
                      className="modal fade"
                      id="November2019Modal"
                      tabindex="-1"
                      aria-labelledby="November2019Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="November2019Label">
                              2019-ի նոյեմբերի թարմացում
                            </h5>
                          </div>
                          <div className="modal-body">
                            <p>
                              Քանի որ Նոր Հաստատության նախագծումն ու պլաններն
                              անցնում են ավարտին, NYAH- ը և ANRC- ն իրենց
                              ջանքերը կենտրոնացնում են Նոր Հաստատության
                              իրագործման համար անհրաժեշտ վերջնական հաստատումներ
                              ստանալու վրա:
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">
                      2019 թվականի սեպտեմբերի թարմացում
                    </h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#September2019Modal"
                    >
                      Տեսեք Թարմացում
                    </a>
                    <div
                      className="modal fade"
                      id="September2019Modal"
                      tabindex="-1"
                      aria-labelledby="September2019Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="September2019Label">
                              2019 թվականի սեպտեմբերի թարմացում
                            </h5>
                          </div>
                          <div className="modal-body">
                            <p>
                              Լրացուցիչ նախագծային առաջարկներից հետո, NYAH- ը և
                              ANRC- ն անցկացնում են կարգավիճակի և պլանավորման
                              մեկ այլ համապարփակ հանդիպում 2019 թ. Սեպտեմբերի
                              11-ին: Խորհրդատուների և խորհրդատուների կողմից
                              ստացված տեղեկությունների համաձայն, ծրագրի
                              մանրամասները մոտենում են վերջնական ձևին:
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">2019-ի հուլիսի թարմացում </h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#July2019Modal"
                    >
                      Տեսեք Թարմացում
                    </a>
                    <div
                      className="modal fade"
                      id="July2019Modal"
                      tabindex="-1"
                      aria-labelledby="July2019Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="July2019Label">
                              2019-ի հուլիսի թարմացում
                            </h5>
                          </div>
                          <div className="modal-body">
                            <ul>
                              <li>
                                Համապատասխանելով 2019 թ.-ի մայիսի 8-ին
                                անդամակցության հաստատմանը `և NYAH- ը, և ANRC- ն
                                վերջնական տեսքի են բերում փոփոխված
                                կանոնադրությունները: Մասնավորապես, NYAH- ի
                                հիմնադրման նորացված վկայագիրը ներկայացվել է NYS
                                Պետդեպարտամենտ, և, նմանապես, ANRC- ի հիմնադրման
                                նորացված վկայագիր ներկայացվել է Նյու Jերսի
                                նահանգի գանձապետարանի դեպարտամենտ:
                              </li>
                              <li>
                                NYAH- ի և ANRC- ի կողմից կառավարման և խորհրդի
                                հաստատումներից բացի, կազմակերպությունները
                                շարունակում են իրենց կապերը և
                                համագործակցությունները `կապված Նոր Հաստատության
                                հետ, ներառյալ 2019 թվականի հուլիսի 22-ին կայացած
                                համապարփակ կարգավիճակի և պլանավորման հանդիպումը:
                              </li>
                              <li>
                                Չնայած շատ աշխատանք կա անելու և հաստատումներ
                                ստանալու, մենք առաջընթաց ունենք:
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">
                      2019 թվականի մայիսի թարմացում
                    </h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#May2019Modal"
                    >
                      Տեսեք Թարմացում
                    </a>
                    <div
                      className="modal fade"
                      id="May2019Modal"
                      tabindex="-1"
                      aria-labelledby="May2019Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="May2019Label">
                              2019 թվականի մայիսի թարմացում
                            </h5>
                          </div>
                          <div className="modal-body">
                            <ul>
                              <li>
                                2019 թ. Մայիսի 8-ին կայացած NYAH- ի
                                Անդամակցության հատուկ ժողովում, NYAH- ի
                                անդամակցությունը հաստատեց 2019 թ. Մարտի 28-ի
                                անդամության պայմանագիրը, որը կնքվել է NYAH,
                                Armenian Home of New York, Inc., ANRC և The
                                Armenian Home կողմից: Հիմնադրամ, Inc.
                              </li>
                              <li>
                                2019 թ. Մայիսի 8-ին մեր հաստատումներին
                                զուգընթաց, ANRC- ի անդամակցությունը նույնպես
                                հաստատեց անդամակցության պայմանագիրը:
                              </li>
                              <li>Մենք ճանապարհին ենք: </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">2018-ի հուլիսի թարմացում </h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#July2018Modal"
                    >
                      Տեսեք Թարմացում
                    </a>
                    <div
                      className="modal fade"
                      id="July2018Modal"
                      tabindex="-1"
                      aria-labelledby="July2018Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="July2018Label">
                              2018-ի հուլիսի թարմացում
                            </h5>
                          </div>
                          <div className="modal-body">
                            <p>
                              2018-ի հուլիսի 30-ին իր անշարժ գույքի վաճառքից
                              հետո NYAH- ը կենտրոնացրեց իր գործողությունները
                              վերսկսելու ջանքերը ՝ համակարգելով և
                              համագործակցելով իր քույր կազմակերպության Home of
                              the Armenian Aged, Inc. d / b / a հայկական
                              բուժքույրական և վերականգնողական կենտրոնի («ANRC»)
                              ) տարեցներին և կարիքավորներին սպասարկող
                              երկարաժամկետ խնամքի նոր հաստատության կառուցման և
                              շահագործման հետապնդման համար:
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">
                      2018 թվականի ապրիլի թարմացում
                    </h5>
                    <a
                      href="#"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#April2018Modal"
                    >
                      Տեսեք Թարմացում
                    </a>
                    <div
                      className="modal fade"
                      id="April2018Modal"
                      tabindex="-1"
                      aria-labelledby="April2018Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="April2018Label">
                              2018 թվականի ապրիլի թարմացում
                            </h5>
                          </div>
                          <div className="modal-body">
                            <p>
                              2018 թ.-ի ապրիլի 30-ին մեր մեծահասակների տունը
                              փակելուց հետո NYAH- ը շարունակեց իր ջանքերը. բ)
                              հայտնաբերել գործող հաստատությունները
                              `գործառնությունները ձեռք բերելու և վերսկսելու
                              համար. գ) նույնականացնել մի կազմակերպություն, որի
                              հետ անդամակցում է գործառնությունները վերսկսելու
                              նպատակով:
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}
